import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Category, Parameter } from '../types/Category';
import DynamicForm from './DynamicForm';
import { SearchParameter, SearchFilter } from '../types/SearchFilter';
import axiosInstance from '../api/axiosInstance';


interface CategoryComponentProps {
  token: string | null;
}





const CategoryComponent: React.FC<CategoryComponentProps> = ({ token }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [parameters, setParameters] = useState<Parameter[]>([]);

  const categoriesUrl : string = process.env.REACT_APP_API_URL + "/categories";
  const inventroriesSearchUrl : string = process.env.REACT_APP_API_URL + "/inventories/search";
  const [searchResults, setSearchResults] = useState<any[]>([]); // State to hold API response
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  useEffect(() => {
    // Fetch categories on component mount
    axiosInstance.get('/categories',
      {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      })
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const category = categories.find(cat => cat.id === selectedId) || null;
    setSelectedCategory(category);
  };

  const handleFormSubmit = (searchFilter: SearchFilter) => {
    console.log('Submitted Search Filter:', searchFilter);
    const response = inventorySearch(searchFilter);
    // You can now use the searchFilter object as needed, e.g., send it to an API
  };




  const inventorySearch = async (searchFilter: SearchFilter) => {
    const toString = JSON.stringify(searchFilter);
    try {
      
      const response = await  axiosInstance.post('/inventories/search',
        searchFilter,       
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        console.log('inventory search result:', response);
        setSearchResults(response.data); // Set the response data to the state
    }       
    catch (error) {
      console.error('Request failed:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Server responded with an error:', error.response);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error setting up request:', error.message);
        }
      } else {
        console.error('Unexpected error:', error);
        setError('Failed to fetch search results.');
      } 
    } 
    finally {
      setLoading(false);
    } 
  };


  return (
    <div>
      <select onChange={handleCategoryChange}>
        <option value="">Select a category</option>
        {categories.map(category => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      
      {selectedCategory && (
        <div>
          <p>Selected Category ID: {selectedCategory.id}</p>
          <p>Selected Category Name: {selectedCategory.name}</p>
          <p>Selected Category description: {selectedCategory.description}</p>
          <DynamicForm 
            parameters={selectedCategory.parameters} 
            categoryId={selectedCategory.id} 
            onSubmit={handleFormSubmit}/>
        </div>
      )}
       {/* Search Results Table */}
       {searchResults.length > 0 && selectedCategory && (
        <div>
          <h3>Search Results</h3>
          <table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                {/* Fixed columns */}
                <th>No</th>
                <th>ID</th>
                <th>Name</th>
                <th>Notes</th>
                <th>URL</th>
                {/* Dynamically render parameter headers */}
                {selectedCategory.parameters.map((param, index) => (
                  <th key={index}>{param.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {searchResults.map((item, index) => (
                <tr key={item.id}>
                  {/* Render fixed columns */}
                  <td>{index + 1}</td>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.notes}</td>
                  <td>{item.url}</td>
                  {/* Render parameter values */}
                  {selectedCategory.parameters.map((param, idx) => {
                    const paramValue = item.parameters?.find((p: Parameter) => p.name === param.name)?.value || '';
                    return <td key={idx}>{paramValue}</td>;
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

    </div>
  );
};

export default CategoryComponent;

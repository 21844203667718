import axios, { AxiosInstance } from 'axios';

const baseURL2 : string = process.env.REACT_APP_API_URL as string;
console.log("baseURL2:", { baseURL2 });

const axiosInstance: AxiosInstance = axios.create({
  //baseURL: baseURL,
  //baseURL: 'http://babahanks-v2-external-lb-1764019540.us-east-2.elb.amazonaws.com:8081',
  baseURL: baseURL2,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;